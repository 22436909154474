<template>
  	<el-dialog title="Registratura" width="75%" :visible.sync="showPopup" class="my-dialog-class" :close-on-click-modal='false'>
		  <span v-if="location == 'formular'">Numar document - {{ NumarDocument }}</span>
		<el-form label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject'  ref='selectedObject' @submit.prevent="save" v-loading="loadingVisible" >
			<el-row :gutter="15" >      
				<el-col :md='8'> 
					<el-form-item label='Emitent' prop="Emitent" >
						<el-input  class='full-width' v-model='selectedObject.Emitent' />
					</el-form-item>
				</el-col> 
				<el-col :md='8'> 
					<el-form-item label='Continut pe scurt' prop="Continut" >
						<el-input  class='full-width' v-model='selectedObject.Continut' />
					</el-form-item>
				</el-col>
				<el-col :md='8'> 
					<el-form-item label='Tip document' prop="TipDocument" >
						<el-input  class='full-width' v-model='selectedObject.TipDocument' />
					</el-form-item>
				</el-col>
				<el-col :md="8">
					<el-form-item label='Repartizare pe grupe' prop="RepartizarePeGrupe" v-if="location != 'formular'">
						<el-select class='full-width' v-model="selectedObject.RepartizarePeGrupe" @change="handleSelectGrupa(selectedObject.RepartizarePeGrupe)" placeholder="Select">
							<el-option v-for="grupa in grupe" :key="grupa.Id" :label="grupa.Nume"  :value="grupa.Id"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :md='8'> 
					<el-form-item label='Responsabil' prop="ResponsabilSef" v-if="location != 'formular'">
						<el-select class='full-width' v-model="selectedObject.ResponsabilSef" placeholder="Select">
							<el-option v-for="angajati in grupaSelectata.Angajati" :key="angajati.Id" :label="angajati.Nume"  :value="angajati.Id"></el-option>
						</el-select>
					</el-form-item>
				</el-col> 
				
				<el-col :md='8' > 
					<el-form-item label='Data document extern' prop="DataDocExtern" v-if="location != 'formular'" >
						<el-date-picker
							class='full-width'
							v-model="selectedObject.DataDocExtern"
							type="date"
							format="dd.MM.yyyy"
							value-format='yyyy-MM-dd'
							placeholder="Alege data">
						</el-date-picker>
					</el-form-item>
				</el-col> 
				
				
				
				<el-col :md='8' v-if="location != 'formular'"> 
					<el-form-item label='Numar document extern' prop="NrDocumentExtern" >
						<el-input class='full-width' v-model='selectedObject.NrDocumentExtern' />
					</el-form-item>
				</el-col> 
				
			</el-row>
			<el-form-item label="Document" v-if="location != 'formular'">
			 <el-col :span="8"> 
                <div class="container-fluid">
                    <el-upload
                       	:action="baseUrl + 'registratura/upload_document_registratura'"
						class="upload-demo"
						multiple
						:on-preview="handlePictureCardPreview"
						:on-remove="remove_pdf"
						:on-success="handleUploadSuccess"
						:file-list="documenteSelectate">
						<el-button type="primary">  <i class="el-icon-upload"></i> Click to upload</el-button>
						<template #tip>
						<div class="el-upload__tip">
						</div>
						</template>
                    </el-upload>
                </div>
            </el-col>
			</el-form-item>
		</el-form>

		<span slot="footer" class="dialog-footer">
			<el-button @click="resetForm('selectedObject')" > Renunta  </el-button>
			<el-button type="primary" @click="save('selectedObject')" > Salveaza </el-button>
		</span>

  	</el-dialog>
</template>

<script>
import BasePage from '@/pages/BasePage'
import settings from "@/backend/LocalSettings";

export default {
    Nume: "Registratura_dialog",
    extends: BasePage,    
    components: {
    },
    data () {
      	return {
			showPopup: false,
			mode: 'add',
            baseUrl: '',
			dialogImageUrl: '',
            previewVisible:false,
			grupe: [],
			grupaSelectata: [],
			location: '',
			documenteSelectate:[],
			NumarDocument: '',
			selectedObject: { 
				Emitent: '', 
				Continut: '', 
				DataCreare: '', 
				DataDocExtern: '', 
				Documente: [], 
				Id:'', 
				NrDocumentExtern: '',
				Numar: '', 
				RepartizarePeGrupe: '', 
				TipDocument: '', 
				ResponsabilSef: '',
				IdUtilizator: ''
			},          
			rules: {
				Emitent: 	 [{ required: true, message: 'Campul este obligatoriu', trigger: 'blur'}],
				// Responsabil: 	 [{ required: true, message: 'Campul este obligatoriu', trigger: 'blur'}],
				// DataDocExtern: 	 [{ required: true, message: 'Campul este obligatoriu', trigger: 'blur'}],
				// RepartizarePeGrupe: 	 [{ required: true, message: 'Campul este obligatoriu', trigger: 'blur'}],
				TipDocument: [{ required: true, message: 'Campul este obligatoriu', trigger: 'blur'}],
				Continut: 	 [{ required: true, message: 'Campul este obligatoriu', trigger: 'blur'}],
			},
			multipleSelection: [],
			tabelListaHeight:{},
			existingVars:[],
			idFormular:''
      }
    },
    methods: {
        show_me: async function( item, location = "registratura", idFormular = -1 ) {
            this.showPopup = true;
			this.location = location;
			this.idFormular = idFormular

			console.log(item);

            if( item == null )
            {
				this.mode = "add";
				this.documenteSelectate = [];
				this.selectedObject = { 
					Emitent: '', 
					Continut: '', 
					DataCreare: '', 
					DataDocExtern: '', 
					Documente: [], 
					Id:'', 
					IdUtilizator: '',
					NrDocumentExtern: '',
					Numar: '', 
					RepartizarePeGrupe: '', 
					TipDocument: '', 
					ResponsabilSef: ''
				};

				// if(location == 'formular'){
					// let res = await this.post('Formulare/get_formular_by_id', {idFormular: idFormular});
				let res = await this.post('registratura/get_id_unic_inregistrare', {idFormular: idFormular});
				this.selectedObject.Emitent = settings.get_user_name();
				this.NumarDocument = res.IdUnic	;
				// }
            }
            else
            {
                this.mode = "edit";
				this.selectedObject = item;
				this.documenteSelectate = this.createList(this.selectedObject.Documente);
				this.handleSelectGrupaInit(this.selectedObject.RepartizarePeGrupe);
            }
        },
		createList(docs) {
			let list = []
			for(let item of docs) {
				// list.push({name: item.Nume, url: ('http://ananp.brk-dev.ro/api/assets/registratura/'+ item.Nume)});
				list.push({name: item.Nume, url: (settings.BASE_URL + 'assets/registratura/'+ item.Nume)});
			}
			return list;
		},	
        async save(formNume) {
			console.log(this.NumarDocument);
			// this.$emit('saved',this.NumarDocument);
			// return
			this.$refs[formNume].validate( async(valid) => {
				if (valid)
				{
					var response = await this.post("registratura/save_item", { mode: this.mode, object: this.selectedObject, location: this.location, id_formular: this.idFormular, numar_document: this.NumarDocument} );
					if(this.location == 'formular'){
						this.$emit('saved',response.IntrareRegistratura.Numar);
						// this.$emit('saved', me.NumarDocument);
					}
					this.resetForm(formNume)
				} else {console.log('eroare validare formular!');}
			});
		},
		resetForm(formNume) {
			this.$refs[formNume].resetFields();
			this.selectedObject = { 
				Emitent: '', 
				Continut: '', 
				DataCreare: '', 
				DataDocExtern: '', 
				Document: '', 
				Id:'', 
				IdUtilizator: '',
				NrDocumentExtern: '',
				Numar: '', 
				RepartizarePeGrupe: '', 
				TipDocument: '', 
				ResponsabilSef: ''
			 };
			this.showPopup = false
			this.$emit("refresh");
		},
		view_pdf(){
            // window.open("http://ananp.brk-dev.ro/api/assets/registratura/" + this.selectedObject.Document, '_blank');
            window.open(settings.BASE_URL + "assets/registratura/" + this.selectedObject.Document, '_blank');
        },
		async remove_pdf(item) {
			await this.post("registratura/delete_pdf", { fisier: item.name} );
			let index = this.selectedObject.Documente.indexOf(item.name); 
			if (index !== -1) {
				this.selectedObject.Documente.splice(index, 1);
			}

			let index2 = this.documenteSelectate.indexOf(item.name); 
			if (index2 !== -1) {
				this.documenteSelectate.splice(index, 1);
			}
		},
		handlePreview(file) {
			console.log(file);
		},
		handleUploadSuccess(response){
			this.selectedObject.Documente.push(response.FileName);
			// this.documenteSelectate.push({name: response.FileName, url: ('http://ananp.brk-dev.ro/api/assets/registratura/'+ response.FileName)})
			this.documenteSelectate.push({name: response.FileName, url: (settings.BASE_URL + 'assets/registratura/'+ response.FileName)})

		},
		handlePictureCardPreview(file) {
			this.dialogImageUrl = file.url.replace('index.php/', '');
			console.log(file.url.replace('index.php/', ''));
			window.open(this.dialogImageUrl, '_blank');
			this.previewVisible = true;
		},
		handleSelectGrupa(idGrupa) {
			this.grupaSelectata = this.grupe.find(grupa => grupa.Id == idGrupa);
			this.selectedObject.ResponsabilSef = '';
		},
		handleSelectGrupaInit(idGrupa) {
			console.log(idGrupa);
			if(idGrupa != 0){
				this.grupaSelectata = this.grupe.find(grupa => grupa.Id == idGrupa);
			}
		},
		setImgUrl() {
                var url = settings.BASE_URL;
                this.baseUrl = url;
        },
		async getGrupe() {
			this.grupe = (await this.post("registratura/get_grupe_de_organizare")).grupe;
		}
    },

    mounted: function() {
        this.setImgUrl();
		this.getGrupe();
    }
};
</script>

<style lang="less" scoped>
	.full-width {
	width: 100%;
	}

	.cvControls {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		margin-left: auto;
		margin-right: auto;
		margin: 10px;
		
	}

	.formular-draggable{
		margin-bottom: 20px;
		.list {
			background-color: #EBEEF5;
			font-size: 14px;
			.header.el-row {
				background-color: #FFF;
				color: #909399;
				font-weight: 500;            
			}
			.el-row {
				background-color: #FFF;
				transition: background-color .25s ease;
				border-bottom: 1px solid #EBEEF5;
				color: black;
				height: 40px;
				.el-col {
					border-bottom: 1px solid #EBEEF5;
					padding-top: 5px;
					padding-bottom: 5px;
					height: 100%;
					padding-left: 10px;
					padding-right: 10px;
					min-width: 0;
					display: flex;
					
					align-items: center;
					line-height: normal;
				}
			}
		}

		.card-tabel-lista{
			margin-bottom:20px;
		}
		.card-principal{
			vertical-align: middle
		}
		.buton-adauga-tabel{
			position: absolute;
			top: 50%;
		}
	}
</style>