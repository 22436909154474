<template>
  	<el-dialog title="Registratura" width="75%" :visible.sync="showPopup" class="my-dialog-class" :close-on-click-modal='false'>
		<el-form label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject'  ref='selectedObject' @submit.prevent="save" v-loading="loadingVisible" >
			<el-row :gutter="15" >
				<el-col :md="8">
					<el-form-item label='Repartizare pe grupe' prop="RepartizarePeGrupe">
						<el-select class='full-width' v-model="selectedObject.RepartizarePeGrupe" @change="handleSelectGrupa(selectedObject.RepartizarePeGrupe)" placeholder="Select">
							<el-option v-for="grupa in grupe" :key="grupa.Id" :label="grupa.Nume"  :value="grupa.Id"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :md='8'> 
					<el-form-item label='Responsabil' prop="ResponsabilSef">
						<el-select class='full-width' v-model="selectedObject.ResponsabilSef" placeholder="Select">
							<el-option v-for="angajati in grupaSelectata.Angajati" :key="angajati.Id" :label="angajati.Nume"  :value="angajati.Id"></el-option>
						</el-select>
					</el-form-item>
				</el-col> 
			</el-row>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button @click="resetForm('selectedObject')" > Renunta  </el-button>
			<el-button type="primary" @click="save('selectedObject')" > Salveaza </el-button>
		</span>

  	</el-dialog>
</template>

<script>
import BasePage from '@/pages/BasePage'
import settings from "@/backend/LocalSettings";

export default {
    Nume: "Registratura_responsabil_dialog",
    extends: BasePage,    
    components: {
    },
    data () {
      	return {
			showPopup: false,
			mode: 'add',
            baseUrl: '',
			dialogImageUrl: '',
            previewVisible:false,
			grupe: '',
			grupaSelectata: '',
			selectedObject: { 
				Emitent: '', 
				Continut: '', 
				DataCreare: '', 
				DataDocExtern: '', 
				Document: '', 
				Id:'', 
				NrDocumentExtern: '',
				Numar: '', 
				RepartizarePeGrupe: '', 
				TipDocument: '', 
				Responsabil: '',
				IdUtilizator: ''
			},          
			rules: {
				Emitent: 	 [{ required: true, message: 'Campul este obligatoriu', trigger: 'blur'}],
				TipDocument: [{ required: true, message: 'Campul este obligatoriu', trigger: 'blur'}],
				Continut: 	 [{ required: true, message: 'Campul este obligatoriu', trigger: 'blur'}],
			},
			multipleSelection: [],
			tabelListaHeight:{},
			existingVars:[]
      }
    },
    methods: {
        show_me: async function( item ) {
            this.showPopup = true;
            if( item == null )
            {
                this.mode = "add";
            }
            else
            {
                this.mode = "edit";
				this.selectedObject = item;
				this.handleSelectGrupaInit(this.selectedObject.RepartizarePeGrupe)
            }
        },
        async save(formNume) {
			this.$refs[formNume].validate( async(valid) => {
				if (valid)
				{	
					this.selectedObject.Responsabil = null;
					await this.post("registratura/save_item", { mode: this.mode, object: this.selectedObject} );
					this.resetForm(formNume)
				} else {console.log('eroare validare formular!');}
			});                
		},
		resetForm(formNume) {
			this.$refs[formNume].resetFields();
			this.selectedObject = { 
				Emitent: '', 
				Continut: '', 
				DataCreare: '', 
				DataDocExtern: '', 
				Document: '', 
				Id:'', 
				IdUtilizator: '',
				NrDocumentExtern: '',
				Numar: '', 
				RepartizarePeGrupe: '', 
				TipDocument: '', 
				Responsabil: ''
			 };
			this.showPopup = false
			this.$emit("refresh");
		},
		view_pdf(){
            // window.open("http://ananp.brk-dev.ro/api/assets/registratura/" + this.selectedObject.Document, '_blank');
            window.open(settings.BASE_URL + "assets/registratura/" + this.selectedObject.Document, '_blank');
        },
		async remove_pdf() {
			await this.post("registratura/remove_pdf", { fisier: this.selectedObject.Document} );
			this.selectedObject.Document = "";
		},
		handleRemove(file, fileList) {
			console.log(file, fileList);
		},
		handlePreview(file) {
			console.log(file);
		},
		handleUploadSuccess(response){
			this.selectedObject.Document = response.FileName;
		},
		handlePictureCardPreview(file) {
			this.dialogImageUrl = file.url;
			this.previewVisible = true;
		},
		handleSelectGrupaInit(idGrupa) {
			this.grupaSelectata = this.grupe.find(grupa => grupa.Id == idGrupa);
		},
		handleSelectGrupa(idGrupa) {
			this.grupaSelectata = this.grupe.find(grupa => grupa.Id == idGrupa);
			this.selectedObject.ResponsabilSef = '';
		},
		setImgUrl() {
                var url = settings.BASE_URL;
                this.baseUrl = url;
        },
		async getGrupe() {
			this.grupe = (await this.post("registratura/get_grupe_de_organizare")).grupe;
		}
    },

    mounted: function() {
        this.setImgUrl();
		this.getGrupe();
    }
};
</script>

<style lang="less" scoped>
	.full-width {
	width: 100%;
	}

	.cvControls {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		margin-left: auto;
		margin-right: auto;
		margin: 10px;
		
	}

	.formular-draggable{
		margin-bottom: 20px;
		.list {
			background-color: #EBEEF5;
			font-size: 14px;
			.header.el-row {
				background-color: #FFF;
				color: #909399;
				font-weight: 500;            
			}
			.el-row {
				background-color: #FFF;
				transition: background-color .25s ease;
				border-bottom: 1px solid #EBEEF5;
				color: black;
				height: 40px;
				.el-col {
					border-bottom: 1px solid #EBEEF5;
					padding-top: 5px;
					padding-bottom: 5px;
					height: 100%;
					padding-left: 10px;
					padding-right: 10px;
					min-width: 0;
					display: flex;
					
					align-items: center;
					line-height: normal;
				}
			}
		}

		.card-tabel-lista{
			margin-bottom:20px;
		}
		.card-principal{
			vertical-align: middle
		}
		.buton-adauga-tabel{
			position: absolute;
			top: 50%;
		}
	}
</style>