<template>
<base-page>
    <titlu-pagina Titlu="Registratura" @on_add_clicked="show_dialog()" :AdaugaVisible='true' />

    <el-card style='margin:5px 0px 5px 0px'>
        <div slot="header" class="clearfix">
            <strong> Filtre </strong>
        </div>
        <div class="filtre">
            <el-form @submit.prevent.native='refresh_info()'>
                <el-row :gutter="20">
                    
                    <el-col :span='4'>
                        <el-form-item label='Emitent'>
                            <el-input v-model='Filters.Emitent'/>
                        </el-form-item>
                    </el-col>
                    <el-col :span='4'>
                        <el-form-item label='Numar document'>
                            <el-input v-model='Filters.NumarDocument'/>
                        </el-form-item>
                    </el-col> 
                    <el-col :span='4'>
                        <el-form-item label='Tip document'>
                            <el-input v-model='Filters.TipDocument'/>
                        </el-form-item>
                    </el-col>   
                    <el-col :md='4'> 
					<el-form-item label='Responsabil' prop="ResponsabilSef">
						<el-select class='full-width' v-model="Filters.ResponsabilSef" placeholder="Select">
							<el-option v-for="angajat in angajati" :key="angajat.Id" :label="angajat.Nume"  :value="angajat.Id"></el-option>
						</el-select>
					</el-form-item>
				</el-col>  
                    <el-col :span='4'>
                        <el-form-item label='Stare' prop="Stare">
                            <el-select class='full-width' v-model="Filters.Stare" placeholder="Select">
                                <el-option label="Creat"  value="creat"></el-option>
                                <el-option label="In lucru" value="in_lucru"></el-option>
                                <el-option label="Respins" value="respins"></el-option>
                                <el-option label="Finalizat"  value="finalizat"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>               
                    <el-col :md='24' >
                        <el-button @click="reset()" > Reset  </el-button>
                        <el-button type='primary' native-type='submit' @click='refresh_info()'> Aplica </el-button>
                    </el-col>
                </el-row>
            </el-form>
            </div>
    </el-card>
           
    <el-table :data="Results" :row-class-name="tableRowClassName" v-loading="loading">
        <el-table-column prop='Emitent' label='Emitent'></el-table-column>
        <el-table-column label='Numar/Data creare'>
            <template slot-scope="scope" >
                {{scope.row.Numar + ' / ' + format_date(scope.row.DataCreare)}}
            </template>
        </el-table-column>
        <el-table-column prop='TipDocument' label='Tip document'></el-table-column>
        <el-table-column prop='Stare' label='Stare'>
            <template slot-scope="scope" >
                {{scope.row.Stare}} <span v-if="scope.row.MotivRespingere != ''"> - </span> {{scope.row.MotivRespingere}}
            </template>
        </el-table-column>
        <el-table-column prop='NumeGrupa' label='Grupa'></el-table-column>
        <el-table-column prop='NumeResponsabilSef' label='Responabil'>
            <template slot-scope="scope" >
                <span v-if="scope.row.Responsabil == '' || scope.row.Responsabil == 0 || scope.row.Responsabil == null"> {{scope.row.NumeResponsabilSef}}</span>          
                <span v-else> {{scope.row.NumeResponsabil}}</span> 
            </template>
        </el-table-column>
        <el-table-column fixed="right" label="Actiuni" width="360px" >
            <template slot-scope="scope" >
                <el-tooltip v-if="scope.row.Documente.length > 0" content="Vizualizare pdf">
                    <el-button type="primary" icon="el-icon-view"  circle @click="view_modal(scope.row.Documente)" />
                </el-tooltip>
                <el-tooltip v-if="scope.row.DocumentRaspuns" content="Vizualizare raspuns pdf">
                    <el-button type="success" icon="el-icon-view"  circle @click="view_pdf(scope.row.DocumentRaspuns)" />
                </el-tooltip>
                <el-tooltip v-if="scope.row.IdUtilizator == currentUserId && scope.row.Stare != 'finalizat' && scope.row.Stare != 'respins' && scope.row.Stare != 'sters'" content="Editare registratura">
                    <el-button type="primary" icon="el-icon-edit"  circle @click="edit_item(scope.row)" />
                </el-tooltip>
                <el-tooltip v-if="scope.row.IdUtilizator == currentUserId && scope.row.Stare != 'finalizat' && scope.row.Stare != 'respins' && scope.row.Stare != 'sters'" content="Sterge">
                    <el-button type="danger" icon="el-icon-delete" circle @click="delete_item(scope.row)" />
                </el-tooltip>
                <el-tooltip v-if="scope.row.Stare == 'creat' && scope.row.NumeGrupa != ''" content="Trimitere" >
                    <el-button type="warning" icon="el-icon-right" circle @click="trimite(scope.row.Id)" />
                </el-tooltip>
                <el-tooltip v-if="scope.row.Stare == 'in_lucru' && (scope.row.IdUtilizator != currentUserId || scope.row.Responsabil == currentAngajatId || scope.row.ResponsabilSef == currentAngajatId)" content="Schimba grupa de repartizare" >
                    <el-button type="primary" icon="el-icon-refresh" circle @click="schimba(scope.row)" />
                </el-tooltip>
                <el-tooltip v-if="scope.row.Stare == 'in_lucru' && (scope.row.IdUtilizator != currentUserId || scope.row.Responsabil == currentAngajatId || scope.row.ResponsabilSef == currentAngajatId)" content="Alege angajat" >
                    <el-button type="primary" icon="el-icon-right" circle @click="avanseaza(scope.row)" />
                </el-tooltip>
                <el-tooltip v-if="scope.row.Stare == 'in_lucru' && (scope.row.IdUtilizator != currentUserId || scope.row.Responsabil == currentAngajatId || scope.row.ResponsabilSef == currentAngajatId)" content="Respinge" >
                    <el-button type="danger" icon="el-icon-close" circle @click="respinge(scope.row.Id)" />
                </el-tooltip>
                <el-tooltip v-if="scope.row.Stare == 'in_lucru' && (scope.row.IdUtilizator != currentUserId || scope.row.Responsabil == currentAngajatId || scope.row.ResponsabilSef == currentAngajatId)" content="Trimite raspuns" >
                    <el-button type="success" icon="el-icon-success" circle @click="aproba(scope.row)" />
                </el-tooltip>
            </template>
        </el-table-column>
    </el-table>
    <el-dialog
        title="Documente"
        :visible.sync="dialogViews"
        width="30%">
        <div v-for="document in documenteSelectate" :key="document.Id">
            <el-tooltip  content="Vizualizare pdf">
                <el-button style="width:100%; margin:4px; " type="primary" icon="el-icon-view" @click="view_pdf(document.Nume)">{{document.Nume}}</el-button>
            </el-tooltip>
        </div>
        
    </el-dialog>
    <el-pagination @size-change="refresh_info" @current-change= "refresh_info" :page-size.sync="PaginationInfo.PerPage" :current-page.sync="PaginationInfo.Page" :total="PaginationInfo.RowCount" layout="pager" />
    <Registratura-dialog ref='dlg' @refresh="refresh_info()" />
    <Registratura-responsabilSef-dialog ref='dlgResp' @refresh="refresh_info()" />
    <Registratura-responsabil-dialog ref='dlgResp2' @refresh="refresh_info()" />
    <Registratura-upload-raspuns-dialog ref='dlgRasp' @refresh="refresh_info()" />

</base-page>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import TitluPagina from '@/widgets/TitluPagina';
import Registratura_dialog from '@/pages/registratura/Registratura_dialog.vue';
import Registratura_responsabilSef_dialog from '@/pages/registratura/Registratura_responsabilSef_dialog.vue';
import Registratura_responsabil_dialog from '@/pages/registratura/Registratura_responsabil_dialog.vue';
import Registratura_upload_raspuns_dialog from '@/pages/registratura/Registratura_upload_raspuns_dialog.vue';
import moment from "moment";

export default {
    Nume: "registratura",
    extends: BasePage,
    components: {
        'base-page': BasePage,
        'titlu-pagina': TitluPagina,
        'Registratura-dialog': Registratura_dialog,
        'Registratura-responsabilSef-dialog': Registratura_responsabilSef_dialog,
        'Registratura-responsabil-dialog': Registratura_responsabil_dialog,
        'Registratura-upload-raspuns-dialog': Registratura_upload_raspuns_dialog,
    },
    data () {
        return {
            Results: [],
            base_url: '',           
            angajati: '',    
            dialogViews: false,    
            documenteSelectate: '',     
            Filters: { Emitent: '', Stare: '', Responsabil: '', TipDocument: '', NumarDocument: '' },                
            PaginationInfo: { Page: 1, PerPage: 50, RowCount: 0, PageSizes: [10, 25, 50, 100, 200] },
            loading: true,
            currentUserId: '' ,
            currentAngajatId:'',
        }
    },
    methods: {
        format_date(date) {
            return moment(date).format('DD.MM.YYYY');
        },

        tableRowClassName({row}) {
            if (row.Stare == 'creat') 
                return '';
            if (row.Stare == 'in_lucru') 
                return 'warning-row';
            if (row.Stare == 'respins' || row.Stare == 'sters') 
                return 'danger-row';
            return 'success-row';
        },
        async get_info() {
            this.getAngajati();
            this.refresh_info();
        },
        async refresh_info(){
            var response    = await this.post("registratura", { Filters: this.Filters, PaginationInfo: this.PaginationInfo});
            this.Results    = response.Result;
            this.loading    = false;
            if(response.User)
                this.currentAngajatId = response.User.IdAngajat;
        },
        reset(){
            this.Filters = {Emitent: '', Stare: '', Responsabil: '', TipDocument: '', NumarDocument: '' };                
            this.refresh_info();
        },   
        view_modal(documente){
            if(documente.length == 1) {
                this.view_pdf( documente[0].Nume )
            } else {
                this.dialogViews = true;
                this.documenteSelectate = documente;
            }
        },
        view_pdf(fisier){
            window.open("https://ananp.brk-dev.ro/api/assets/registratura/" + fisier, '_blank');
        },
        async getAngajati() {
			this.angajati = (await this.post("registratura/get_angajati")).angajati;
		},
        async delete_item( item ){
            var confirm =  await this.$confirm(`Sunteti sigur?`, 'Warning');
            if( confirm )
            {
                await this.post("registratura/delete_registratura", { id: item.Id } );
                this.refresh_info();
            }
        },
        schimba(item){
            this.$refs.dlgResp.show_me(item);
        },
        avanseaza(item){
            this.$refs.dlgResp2.show_me(item);
        },
        async edit_item(item){
            this.$refs.dlg.show_me(item);
        },
        show_dialog(id){
            this.$refs.dlg.show_me(id);
        },   
        async trimite(id) {
            var confirm =  await this.$confirm(`Sunteti sigur ?`, 'Warning');
            if( confirm )
            {
                await this.post("registratura/seteaza_registratura_in_lucru", { id: id } );
                this.refresh_info();
            }
        },
         async aproba(item) {
                this.$refs.dlgRasp.show_me(item);
                this.refresh_info();
        },
         async respinge(id) {
            this.$prompt(`Introduceti motivul respingerii`, {               
                type: 'warning',
                inputPattern: /./,
                inputErrorMessage: 'Introduceti motivul respingerii'
                }).then(async ({value})  => {
                    await this.post("registratura/seteaza_registratura_respins", { id: id, motiv: value } );
                    this.refresh_info();                                                                  
                }).catch(() => {
                    this.$message({type: 'info', message: 'Anulat'});                                                                 
                });
            }
    },
    mounted(){
        this.base_url = settings.BASE_URL;
        this.currentUserId = settings.get_user_id();
        this.get_info();        
    }
};
</script>

<style>

.top50{
  margin-top: 20px;
}

.filtre .el-input-number {
    width: 100% !important;
}

.el-table .danger-row {
    background: #f05b6c;
    color: #fff;
}

.el-table .danger-row:hover {
    color: rgb(52, 52, 52);
}

.el-table .warning-row {
    background: rgb(255, 226, 171);
}

.el-table .success-row {
    background: #cbffae;
}

</style>
