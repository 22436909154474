<template>
  	<el-dialog title="Raspuns - este obligatorie incarcarea unui raspuns" width="75%" :visible.sync="showPopup" class="my-dialog-class" :close-on-click-modal='false'>
		<el-form label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject'  ref='selectedObject' @submit.prevent="save" v-loading="loadingVisible" >
			<el-form-item label="Document raspuns">
			 <el-col :span="8"> 
                <div class="container-fluid">
                    <el-upload
						v-if="!selectedObject.DocumentRaspuns"
                       	:action="baseUrl + 'registratura/upload_document_registratura'"
						list-type="picture-card"
						:on-preview="handlePictureCardPreview"
						:on-remove="delete_pdf"
						:on-success="handleUploadSuccess">
                        <i class="el-icon-upload"></i>
                    </el-upload>
                </div>
                <div v-if="selectedObject.DocumentRaspuns != '' && selectedObject.DocumentRaspuns  != null">
                    <div class="cvControls">
                        <div><el-button type="success" @click="view_pdf()">Vizualizare document</el-button></div>
                        <div><el-button type="danger" @click="delete_pdf()">Stergere document</el-button></div>
                    </div>
                </div>            
            </el-col>
			</el-form-item>
		</el-form>

		<span slot="footer" class="dialog-footer">
			<el-button @click="resetForm('selectedObject')" > Renunta  </el-button>
			<el-button type="primary" :disabled="selectedObject.DocumentRaspuns == '' || selectedObject.DocumentRaspuns == 'null' " @click="save('selectedObject')" > Salveaza </el-button>
		</span>

  	</el-dialog>
</template>

<script>
import BasePage from '@/pages/BasePage'
import settings from "@/backend/LocalSettings";

export default {
    Nume: "Registratura_upload_raspuns_dialog",
    extends: BasePage,    
    components: {
    },
    data () {
      	return {
			showPopup: false,
			mode: 'add',
            baseUrl: '',
			dialogImageUrl: '',
            previewVisible:false,
			selectedObject: { 
				Emitent: '', 
				Continut: '', 
				DataCreare: '', 
				DataDocExtern: '', 
				Document: '', 
				Id:'', 
				NrDocumentExtern: '',
				Numar: '', 
				RepartizarePeGrupe: '', 
				TipDocument: '', 
				Responsabil: '',
				IdUtilizator: '',
				DocumentRaspuns: ''
			},      
			rules: {
			},
      }
    },
    methods: {
        show_me: async function( item ) {
            this.showPopup = true;
			this.mode = "edit";
			this.selectedObject = item;
        },
        async save(formNume) {
			this.$refs[formNume].validate( async(valid) => {
				if (valid)
				{
					await this.post("registratura/save_item", { mode: this.mode, object: this.selectedObject} );
					if(this.selectedObject.DocumentRaspuns)
                		await this.post("registratura/seteaza_registratura_aprobat", { id:  this.selectedObject.Id } );
					this.resetForm(formNume)
				} else {console.log('eroare validare formular!');}
			});                
		},
		resetForm(formNume) {
			this.$refs[formNume].resetFields();
			this.selectedObject = { 
				Emitent: '', 
				Continut: '', 
				DataCreare: '', 
				DataDocExtern: '', 
				Document: '', 
				Id:'', 
				IdUtilizator: '',
				NrDocumentExtern: '',
				Numar: '', 
				RepartizarePeGrupe: '', 
				TipDocument: '', 
				Responsabil: '',
				DocumentRaspuns: ''
			 };
			this.showPopup = false
			this.$emit("refresh");
		},
		view_pdf(){
            // window.open("http://ananp.brk-dev.ro/api/assets/registratura/" + this.selectedObject.DocumentRaspuns, '_blank');
            window.open(settings.BASE_URL + "assets/registratura/" + this.selectedObject.DocumentRaspuns, '_blank');
        },
		async delete_pdf() {
			await this.post("registratura/delete_pdf", { fisier: this.selectedObject.DocumentRaspuns} );
			this.selectedObject.DocumentRaspuns = "";
		},
		handleRemove(file, fileList) {
			console.log(file, fileList);
		},
		handlePreview(file) {
			console.log(file);
		},
		handleUploadSuccess(response){
			this.selectedObject.DocumentRaspuns = response.FileName;
		},
		handlePictureCardPreview(file) {
			this.dialogImageUrl = file.url;
			this.previewVisible = true;
		},
		setImgUrl() {
                var url = settings.BASE_URL;
                this.baseUrl = url;
        },
    },

    mounted: function() {
        this.setImgUrl();
    }
};
</script>

<style lang="less" scoped>
	.full-width {
	width: 100%;
	}

	.cvControls {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		margin-left: auto;
		margin-right: auto;
		margin: 10px;
		
	}

	.formular-draggable{
		margin-bottom: 20px;
		.list {
			background-color: #EBEEF5;
			font-size: 14px;
			.header.el-row {
				background-color: #FFF;
				color: #909399;
				font-weight: 500;            
			}
			.el-row {
				background-color: #FFF;
				transition: background-color .25s ease;
				border-bottom: 1px solid #EBEEF5;
				color: black;
				height: 40px;
				.el-col {
					border-bottom: 1px solid #EBEEF5;
					padding-top: 5px;
					padding-bottom: 5px;
					height: 100%;
					padding-left: 10px;
					padding-right: 10px;
					min-width: 0;
					display: flex;
					
					align-items: center;
					line-height: normal;
				}
			}
		}

		.card-tabel-lista{
			margin-bottom:20px;
		}
		.card-principal{
			vertical-align: middle
		}
		.buton-adauga-tabel{
			position: absolute;
			top: 50%;
		}
	}
</style>