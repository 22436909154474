<template>
<div class='titlu'>
    <el-row>
        <el-col :span="20">
            <h1> {{Titlu}} </h1>
        </el-col>
        <el-col :span="4" v-if="AdaugaVisible">
            <el-button type='primary' class='btn-adauga' @click="on_add_clicked" icon='el-icon-plus'> {{AdaugaText}} </el-button>
        </el-col>
        <el-col v-if="PrintVisible" :span="4">
            <el-button type='primary' class='btn-print' @click="on_print_clicked" icon='el-icon-printer'></el-button>
        </el-col>
    </el-row>
</div>
</template>

<script>

import settings from '@/backend/LocalSettings';

export default {
    name: 'TitluPagina',
    props:{
        Titlu: "",
        AdaugaText: "Adauga",
        AdaugaVisible: true,
        PrintVisible: true,
    },
    data () {
        return {
        }
    },
    methods:
        {
            on_add_clicked: function(){
                this.$emit('on_add_clicked');
            },
            on_print_clicked: function(){
                this.$emit('on_print_clicked');
            },
        }
}
</script>

<style scoped lang="less">
.btn-print {
   float: right;
            height: 68px;
            padding-left: 40px;
            padding-right: 40px;
            background: #34c2e4; /* Old browsers */
            background: -moz-linear-gradient(top, #34c2e4 0%, #42b6df 50%, #4fabda 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top, #34c2e4 0%,#42b6df 50%,#4fabda 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom, #34c2e4 0%,#42b6df 50%,#4fabda 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#34c2e4', endColorstr='#4fabda',GradientType=0 ); /* IE6-9 */
            border: none;
            border-radius: 0%;
            margin: 2px;
            font-size: 20px;
}

</style>
